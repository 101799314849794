import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../SearchFolderOptions/SearchFolderOptionsTable.module.scss';
import '../../../GlobalStyle.scss';
// import TableRuleLogTable from './RuleLogTable.js';
import SearchTablesData from './RuleLogData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { deleteRuleLog, searchForRuleLogTable, updateRuleLog, searchForRuleLogDropDown } from './RuleLogService.js';
import { searchUnitOfWork } from '../UnitOfWork/UnitOfWorkService.js';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices.js';
//Resusable Components Start
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl.js';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton.js';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js';
// import Footer from '../../../newcomponents/Footer/Footer';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';
import { invalidChars } from '../../../utils/helper/globalFunc.js';

//Resusable Components End

var _ = require('lodash');
const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Rule Log', link: '' },
];
// const displayNameRuleLog = [
//   { value: 1, label: 'Lorem1' },
//   { value: 2, label: 'Lorem2' },
//   { value: 3, label: 'Lorem3' },
// ];

const TableRuleLogTable = lazy(() => import('./RuleLogTable.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));

export default function SearchFolderTable() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  //   const data123= {
  //     id: '',
  //     ruleId: '',
  //     logId: '',
  //     ruleRunDttm: null,
  //     ruleRunIncrement: '',
  //     unitOfWork: '',
  //     ruleManager: '',
  //     confidenceLevel: '',
  //     dataValue: '',
  //     visualConfirmationIndicator: false,
  //     additionalText: '',
  //     visualStartY: '',
  //     visualEndX: '',
  //     visualStartX: '',
  //     transformedDataValue: '',
  //     visualEndY: '',
  // }
  const [formData, setFormData] = useState({
    contextId: userInfoContext,
    id: '',
    ruleId: '',
    ruleDisplayName: '',
    logId: '',
    ruleRunIncrement: '',
    ruleRunDttm: '',
    unitOfWork: null,
    unitOfWorkUniqueId: null,
    ruleManager: '',
    confidenceLevel: '',
    dataValue: '',
    fieldName: '',
    transformedDataValue: '',
    dataValueHistory: null,
    additionalText: '',
    visualConfirmationIndicator: null,
    pageId: '',
    visualStartX: '',
    visualStartY: '',
    visualEndX: '',
    visualEndY: '',
    buowId: '',
    fieldName: '',
    definition: null,
    checked: null,
    activeInd: true,
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [searchTableDetails, setSearchTableDetails] = useState(SearchTablesData);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchSearchOptionsData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForRuleLogTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchSearchOptionsData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchForRuleLogTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  // const createNewFunc = () => {
  //   setFormData({
  //     contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
  //     id: '',
  //     ruleId: '',
  //     ruleRunDttm: null,
  //     ruleRunIncrement: '',
  //     unitOfWork: '',
  //     ruleManager: '',
  //     confidenceLevel: '',
  //     dataValue: '',
  //     visualConfirmationIndicator: null,
  //     additionalText: '',
  //     visualStartY: '',
  //     visualEndX: '',
  //     visualStartX: '',
  //     transformedDataValue: '',
  //     visualEndY: '',
  //   });
  //   setIsNew(true);
  //   setRecordModalShow(true);
  // };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };
    deleteRuleLog(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchSearchOptionsData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Rule Log deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data?.contextId,
      id: data?.id,
      ruleId: data?.ruleId,
      logId: data?.logId,
      ruleRunIncrement: data?.ruleRunIncrement,
      ruleRunDttm: data?.ruleRunDttm,
      unitOfWork: data?.unitOfWork,
      unitOfWorkUniqueId: data?.unitOfWorkUniqueId,
      ruleManager: data?.ruleManager,
      confidenceLevel: data?.confidenceLevel,
      dataValue: data?.dataValue,
      fieldName: data?.fieldName,
      transformedDataValue: data?.transformedDataValue,
      dataValueHistory: data?.dataValueHistory,
      additionalText: data?.additionalText,
      visualConfirmationIndicator: data?.visualConfirmationIndicator,
      pageId: data?.pageId,
      visualStartX: data?.visualStartX,
      visualStartY: data?.visualStartY,
      visualEndX: data?.visualEndX,
      visualEndY: data?.visualEndY,
      buowId: data?.buowId,
      fieldName: data?.fieldName,
      definition: data?.definition,
      checked: data?.checked,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForRuleLogTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUsersTableData = JSON.parse(JSON.stringify(searchTableDetails));
    newUsersTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setSearchTableDetails(newUsersTableData);
    await fetchNewUsersTableData();
  };

  // Code needs to change
  const fetchNewUsersTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchForRuleLogTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchForRuleLogTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.usersTablePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.usersTablePageContent}>
        <h1 className={styles.pageTitle}>Rule Log</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div></div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.usersTableTable, 'glbUsersTableTable'].join(' ')}>
          <TableRuleLogTable
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={searchTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditUsersTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          setLoading={setLoading}
          fetchSearchOptionsData={fetchSearchOptionsData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle="Rule Log"
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditUsersTable = (props) => {
  // const [isSuperUser, setIsSuperUser] = useState(false);
  // const [passwordVisible, setPasswordVisible] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  // const filterRuleLogName = (inputValue) => {
  //   return displayNameRuleLog.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  // };

  const loadOptionsRule = async (inputValue, callback) => {
    let payload = {
      text: inputValue,
      column: '',
      order: '',
    };
    if (inputValue === '') {
      // setSearchData([]);
    } else {
      await searchForRuleLogDropDown(payload).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.ruleDisplayName, value: res.ruleId };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  const loadOptionsUnitOfWork = async (inputValue, callback) => {
    let payload = {
      text: inputValue,
      column: '',
      order: '',
    };
    if (inputValue === '') {
      // setSearchData([]);
    } else {
      await searchUnitOfWork(payload).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.unitOfWork, value: res.unitOfWork };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  // const promiseOptions = (inputValue) =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(filterRuleLogName(inputValue));
  //     }, 1000);
  //   });
  // const loadRuleLogName = async (inputValue, callback) => {
  //   if (inputValue === '') {
  //     // setSearchDataBUOW([]);
  //   } else {
  //     await getSearchContextId(inputValue).then(async (response1) => {
  //       let options = await response1.data.map((res) => {
  //         return { label: res.contextId, value: res.contextId };
  //       });
  //       // setSearchDataBUOW(options);
  //       callback(options);
  //     });
  //   }
  // };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: userInfoContext,
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const onChangeRule = (event) => {
    if (event == null) {
      return;
    }
    const { label, value } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? '' : label;
    props.setFormData((prevData) => ({
      ...prevData,
      ruleId: updatedValue,
      ['definition']: {
        ...prevData.definition,
        ruleDisplayName: updatedLabel,
        ruleId: updatedValue,
      },
    }));
  };

  const onChangeUnitOfWork = (event) => {
    if (event == null) {
      return;
    }
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      unitOfWork: updatedValue,
    }));
  };

  const [isRequiredError, setisRequiredError] = useState(false);

  useEffect(() => {
    setisRequiredError(false);
    // setPasswordVisible(false);
  }, [props.formData, props.isNew]);

  const handleInputName = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: name !== 'description' ? (name === 'systemFolderLevel' ? parseInt(removeSpace.trimStart()) : value.trimStart()) : value.trimStart(),
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  // const handleSubmit = async () => {
  //   try {
  //     if (
  //       props.formData.contextId === null ||
  // props.formData.contextId === '' ||
  // props.formData.id === null ||
  // props.formData.id === '' ||
  // props.formData.rule === null ||
  // props.formData.rule === '' ||
  // props.formData.ruleRunDttm === null ||
  // props.formData.ruleRunDttm === '' ||
  // props.formData.ruleRunIncrement === null ||
  // props.formData.ruleRunIncrement === '' ||
  // props.formData.unitOfWork?.length > 15 ||
  // props.formData.unitOfWorkUniqueId?.length > 15 ||
  // props.formData.confidenceLevel?.length > 20 ||
  // props.formData.visualStartX?.length > 20 ||
  // props.formData.visualStartY?.length > 20 ||
  // props.formData.visualEndX?.length > 20 ||
  // props.formData.visualEndY?.length > 20 ||
  // props.formData.contextId?.length > 50 ||
  // props.formData.ruleManager?.length > 50 ||
  // props.formData.dataValue?.length > 100 ||
  // props.formData.additionalText?.length > 100 ||
  // props.formData.transformedDataValue?.length > 100
  //     ) {
  //       setisRequiredError(true);
  //       return;
  //     }

  //     let payload = _.cloneDeep(props.formData);
  //     props.isLoading(true);
  //     // await createRuleLogTable(payload)
  //     //   .then((response) => {
  //     //     if (response.data) {
  //     //       props.fetchSearchOptionsData();
  //     //       props.setRecordModalShow(false);
  //     //       props.setIsSuccessGrowlHidden(false);
  //     //       props.setMessage('Rule Log created successfully');

  //     //       setTimeout(() => {
  //     //         props.setIsSuccessGrowlHidden(true);
  //     //       }, 3000);
  //     //       props.isLoading(false);
  //     //       setIsSuperUser(false);
  //     //     } else if (response.error) {
  //     //       props.setIsErrorGrowlHidden(false);
  //     //       props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

  //     //       setTimeout(() => {
  //     //         props.setIsErrorGrowlHidden(true);
  //     //       }, 3000);
  //     //       props.isLoading(false);
  //     //     }
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log(err);
  //     //   });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleEdit = async () => {
    if (
      props.formData.contextId === null ||
      props.formData.contextId === '' ||
      props.formData.id === null ||
      props.formData.id === '' ||
      props.formData.rule === null ||
      props.formData.rule === '' ||
      props.formData.ruleRunDttm === null ||
      props.formData.ruleRunDttm === '' ||
      props.formData.ruleRunIncrement === null ||
      props.formData.ruleRunIncrement === '' ||
      props.formData.unitOfWork?.length > 15 ||
      props.formData.unitOfWorkUniqueId?.length > 15 ||
      props.formData.confidenceLevel?.length > 20 ||
      props.formData.visualStartX?.length > 20 ||
      props.formData.visualStartY?.length > 20 ||
      props.formData.visualEndX?.length > 20 ||
      props.formData.visualEndY?.length > 20 ||
      props.formData.contextId?.length > 50 ||
      props.formData.ruleManager?.length > 50 ||
      props.formData.dataValue?.length > 100 ||
      props.formData.additionalText?.length > 100 ||
      props.formData.transformedDataValue?.length > 100
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    payload.unitOfWork = payload?.unitOfWork?.trim();
    payload.ruleManager = payload?.ruleManager?.trim();
    payload.confidenceLevel = payload?.confidenceLevel?.trim();
    payload.dataValue = payload?.dataValue?.trim();
    payload.fieldName = payload?.fieldName?.trim();
    payload.transformedDataValue = payload?.transformedDataValue?.trim();
    payload.additionalText = payload?.additionalText?.trim();
    payload.visualStartX = payload?.visualStartX?.trim();
    payload.visualStartY = payload?.visualStartY?.trim();
    payload.visualEndX = payload?.visualEndX?.trim();
    payload.visualEndY = payload?.visualEndY?.trim();
    payload.buowId = payload?.buowId?.trim();
    payload.fieldName = payload?.fieldName?.trim();

    props.setLoading(true);
    await updateRuleLog(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Rule Log updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchSearchOptionsData();
        props.setRecordModalShow(false);
        props.setLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <Modal
      {...props}
      className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Rule Log' : 'Edit Rule Log'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.usersTableModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {!props.isNew && (
                // <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                </div>
                // </div>
              )}

              {/* <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Work Group ID </label>
                <input name="name" disabled={true} value={"abc_Test"} onChange={handleInputName} />
                {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.name?.length > 50 && handleMaxLimitError(50)}
              </div> */}

              <div className={[styles.inputFieldInternalDiv, `${!props.isNew ? 'col-6' : 'col-12'}`].join(' ')}>
                <label>Rule *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={!props.isNew ? false : true}
                  defaultOptions
                  cacheOptions
                  loadOptions={loadOptionsRule}
                  value={
                    props?.formData?.definition?.ruleDisplayName !== ''
                      ? { label: props?.formData?.definition?.ruleDisplayName, value: props?.formData?.definition?.ruleDisplayName }
                      : { label: '---Select---' }
                  }
                  onChange={onChangeRule}
                />
                {/* {props.formData.rule?.length > 50 && isRequiredError === true && handleMaxLimitError(50)} */}
                {(props.formData.rule === null || props.formData.rule === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Rule Run DTTM *</label>
                <input disabled={true} name="ruleRunDttm" value={props.formData.ruleRunDttm} onChange={handleInputName} />
                {/* {props.formData.ruleRunDttm?.length > 50 && isRequiredError === true && handleMaxLimitError(50)} */}
                {(props.formData.ruleRunDttm === null || props.formData.ruleRunDttm === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Rule Run Increment *</label>
                <input
                  pattern="^(0|[1-9][0-9]*)$"
                  onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()}
                  type="number"
                  name="ruleRunIncrement"
                  value={props.formData.ruleRunIncrement}
                  onChange={handleInputName}
                />
                {/* {props.formData.ruleRunIncrement?.length > 50 && isRequiredError === true && handleMaxLimitError(50)} */}
                {(props.formData.ruleRunIncrement === null || props.formData.ruleRunIncrement === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Unit of Work</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={!props.isNew ? false : true}
                  defaultOptions
                  cacheOptions
                  loadOptions={loadOptionsUnitOfWork}
                  value={props?.formData?.unitOfWork !== '' ? { label: props?.formData?.unitOfWork, value: props?.formData?.unitOfWork } : { label: '---Select---' }}
                  onChange={onChangeUnitOfWork}
                />
                {props.formData.unitOfWork?.length > 15 && isRequiredError === true && handleMaxLimitError(15)}
                {/* {(props.formData.unitOfWork === null || props.formData.unitOfWork === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Rule Manager</label>
                <input name="ruleManager" value={props.formData.ruleManager} onChange={handleInputName} />
                {props.formData.ruleManager?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                {/* {(props.formData.ruleManager === null || props.formData.ruleManager === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Confidence Level</label>
                <input name="confidenceLevel" value={props.formData.confidenceLevel} onChange={handleInputName} />
                {props.formData.confidenceLevel?.length > 20 && isRequiredError === true && handleMaxLimitError(20)}
                {/* {(props.formData.confidenceLevel === null || props.formData.confidenceLevel === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Data Value</label>
                <input name="dataValue" value={props.formData.dataValue} onChange={handleInputName} />
                {props.formData.dataValue?.length > 100 && isRequiredError === true && handleMaxLimitError(100)}
                {/* {(props.formData.dataValue === null || props.formData.dataValue === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={styles.radioInputOuterDiv}>
                  <div className="col-6 pl-0">Visual confirmation Indicator </div>
                  <div className="col-6" style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      name="visualConfirmationIndicator"
                      value={true}
                      checked={props.formData.visualConfirmationIndicator === true}
                      onChange={handleRadioChange}
                      // disabled={props.isNew === true ? true : props.userRole?.includes('Admin') ? false : true}
                    />{' '}
                    True
                    <input
                      type="radio"
                      name="visualConfirmationIndicator"
                      value={false}
                      checked={props.formData.visualConfirmationIndicator === false}
                      onChange={handleRadioChange}
                      // disabled={props.isNew === true ? true : props.userRole?.includes('Admin') ? false : true}
                    />{' '}
                    False
                  </div>
                </div>
              </div>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Additional Text</label>
                <input name="additionalText" value={props.formData.additionalText} onChange={handleInputName} />
                {props.formData.additionalText?.length > 100 && isRequiredError === true && handleMaxLimitError(100)}
                {/* {(props.formData.additionalText === null || props.formData.additionalText === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Visual Start X</label>
                <input name="visualStartX" value={props.formData.visualStartX} onChange={handleInputName} />
                {props.formData.visualStartX?.length > 20 && isRequiredError === true && handleMaxLimitError(20)}
                {/* {(props.formData.visualStartX === null || props.formData.visualStartX === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Visual Start Y</label>
                <input name="visualStartY" value={props.formData.visualStartY} onChange={handleInputName} />
                {props.formData.visualStartY?.length > 20 && isRequiredError === true && handleMaxLimitError(20)}
                {/* {(props.formData.visualStartY === null || props.formData.visualStartY === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Visual End X</label>
                <input name="visualEndX" value={props.formData.visualEndX} onChange={handleInputName} />
                {props.formData.visualEndX?.length > 20 && isRequiredError === true && handleMaxLimitError(20)}
                {/* {(props.formData.visualEndX === null || props.formData.visualEndX === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Visual End Y</label>
                <input name="visualEndY" value={props.formData.visualEndY} onChange={handleInputName} />
                {props.formData.visualEndY?.length > 20 && isRequiredError === true && handleMaxLimitError(20)}
                {/* {(props.formData.visualEndY === null || props.formData.visualEndY === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Transformed Data Value</label>
                <input name="transformedDataValue" value={props.formData.transformedDataValue} onChange={handleInputName} />
                {props.formData.transformedDataValue?.length > 100 && isRequiredError === true && handleMaxLimitError(100)}
                {/* {(props.formData.transformedDataValue === null || props.formData.transformedDataValue === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>} */}
              </div>

              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>BUOW ID</label>
                <input disabled name="buowId" value={props.formData.buowId} onChange={handleInputName} />
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, `${'col-6'}`].join(' ')}>
                <label>Field Name</label>
                <input disabled name="fieldName" value={props.formData.fieldName} onChange={handleInputName} />
              </div>

              <div className={[styles.inputFieldInternalDiv, 'col-6 justify-content-center'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.usersTableFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              // <CustomButton title="Save" disabled={false} className={styles.saveRecordBtn} onClick={handleSubmit} />
              <></>
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
