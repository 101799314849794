import axios from 'axios';
export async function getSearchOptionsTable() {
    let workQueueFilterResponse = [];
    try {
      const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemfolderoptions';
      await axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
          },
        })
        .then((response) => {
          workQueueFilterResponse = response;
        });
    } catch (ex) {
      console.log('Error in get messages ', ex.message);
    }
    return workQueueFilterResponse;
}
  

export async function createSearchOptionsFolderTable(data) {
    let createResponse = [];
    try {
      const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemfolderoptions';
      await axios
        .post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
          },
        })
        .then((response) => {
          createResponse = response;
        });
    } catch (ex) {
      console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
      createResponse.error = ex.response.data;
    }
    // console.log(createResponse);
    return createResponse;
  }
  
  export async function updateSearchFolderTable(data) {
    let updateResponse = [];
    try {
      const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemfolderoptions/' + data.id+'/';
      await axios
        .put(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
          },
        })
        .then((response) => {
          updateResponse = response;
        });
    } catch (ex) {
      console.log('Error in scanOnDemandService.js claimJob()', ex.message);
      updateResponse.error = ex.response.data;
    }
    return updateResponse;
  }
  
  export async function deleteSearchFolder(data) {
    let deleteResponse = [];
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/systemfolderoptions/${data.userId}`;
      await axios
        .delete(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
          },
        })
        .then((response) => {
          deleteResponse = response;
        });
    } catch (ex) {
      console.log('Error', ex.message);
      deleteResponse.error = ex.response.data;
    }
    return deleteResponse;
  }
  
  export async function searchForOptionsServicesTable(data) {
    let workQueueFilterResponse = [];
    try {
      let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/systemfolderoptions/';
      if (data.text !== '' && data.column === '' && data.order === '') {
        url += 'search?keywords=' + data.text;
      } else {
        url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
      }
  
      await axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
          },
        })
        .then((response) => {
          workQueueFilterResponse = response;
          sessionStorage.setItem('SystemColumns', JSON.stringify(response.data));

        });
    } catch (ex) {
      console.log('Error in get messages ', ex.message);
    }
    return workQueueFilterResponse;
  }
  
  export async function sortUsersTable(data) {
    let workQueueFilterResponse = [];
    try {
      const url = process.env.REACT_APP_BACKEND_URL + '/security_api/user/sort/' + data.column + '/' + data.order;
      await axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
          },
        })
        .then((response) => {
          workQueueFilterResponse = response;
        });
    } catch (ex) {
      console.log('Error in get messages ', ex.message);
      workQueueFilterResponse.error = ex.response.data;
    }
    return workQueueFilterResponse;
  }
  
  
  export async function getRoles(text) {
    let workQueueFilterResponse = [];
    try {
      const url = process.env.REACT_APP_BACKEND_URL + '/security_api/role/search/' + text
      await axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + sessionStorage.getItem('userToken'),
  
          },
        })
        .then((response) => {
          workQueueFilterResponse = response;
        });
    } catch (ex) {
      console.log('Error in get messages ', ex.message);
    }
    return workQueueFilterResponse;
  }