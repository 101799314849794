import React from 'react';
import DiscardIcon from '../../../fsa-style/img/svgs/discard-icon.svg';
import { Modal } from 'react-bootstrap';
import styles from './PageNumberConfirmationModal.module.scss';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';

export const PageNumberConfirmationModal = (props) => {
  return (
    <Modal
      show={props.visible}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.deleteContainer}
      onHide={props.onHide}
      style={{ zIndex: 999999 }}
      backdrop="static"
    >
      <div className={styles.confirmation_modal_popup}>
        <Modal.Body style={{padding: '5rem 3rem 3rem'}}>
          <div className={styles.flex_container_custom}>
            <div className={styles.confirmation_modal_text_wrapper}>
              <img src={DiscardIcon} alt="cross icon" style={{borderRadius: '50%'}}/>
              <div className={styles.confirmation_modal_text}>
                {props.text}
              </div>
            </div>

            <div className={styles.modal_footer_section}>
              <CustomButton
                title="Ok"
                className={styles.modal_footer_delete_button}
                onClick={() => {
                  props.onConfirm();
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
