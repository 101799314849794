import React, { useState, useEffect, lazy } from 'react';
import '../../../components/FileCode/FileCode.scss';
import RolesTableData from './ObjectDictionaryObjectData.json';
// import TableRolesTable from './TableRolesTable';
import {
  getPermissions,
  createObjectTable,
  updateObjectTable,
  deleteRolesTable,
  searchObjectTable,
  getSearchContextId,
  getObjectTypeList,
} from './ObjectDictionaryObjectServices.js';
import styles from './ObjectDictionaryObject.module.scss';
import '../../../GlobalStyle.scss';

import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import AsyncSelect from 'react-select/async';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Object Dictionary Object', link: '' },
];

const TableRolesTable = lazy(() => import('./ObjectDictionaryObjectTable.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));

export default function ObjectDictionaryObjectTable() {
  let user_info = JSON.parse(window.sessionStorage.getItem('user_information'));
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [createEditModalShow, setCreateEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDeleteRowData, setSelectedDeleteRowData] = useState();
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [rolesTableDetails, setRolesTableData] = useState(RolesTableData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    contextId: user_info?.contextId,
    name: '',
    objectType: '',
    activeInd: true,
    description: '',
    displayTemplate: '',
  });

  async function fetchRolesTableData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
      contextId: user_info?.contextId,
    };

    await searchObjectTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(false);
    // fetchRolesTableData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);

    let payload = {
      text: searchText,
      column: '',
      order: '',
      contextId: user_info?.contextId,
    };
    setSorting({ column: '', order: '' });

    await searchObjectTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: user_info?.contextId,
      name: '',
      objectType: '',
      activeInd: true,
      description: '',
      displayTemplate: '',
    });
    setIsNewRecord(true);
    setCreateEditModalShow(true);
  };

  const EditRowDataFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      name: data.name,
      objectType: data.objectType,
      activeInd: data.activeInd,
      description: data.description,
      objectId: data.objectId,
      displayTemplate: data?.displayTemplate ? data.displayTemplate : '',
    });

    setIsNewRecord(false);
    setCreateEditModalShow(true);
  };

  const DeleteRowDataFunc = (DeleteRowData) => {
    setDeleteModalShow(true);
    setSelectedDeleteRowData(DeleteRowData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
      contextId: user_info?.contextId,
    };

    await searchObjectTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.objectId,
    };
    deleteRolesTable(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchRolesTableData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Object Dictionary Object deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoading(false);
      }
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRolesTableData = JSON.parse(JSON.stringify(rolesTableDetails));
    newRolesTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setRolesTableData(newRolesTableData);
    await fetchNewRolesTableData();
  };

  const fetchNewRolesTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: user_info?.contextId,
    };
    await searchObjectTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
      contextId: user_info?.contextId,
    };
    await searchObjectTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.rolesTablePage} data-testid="rolesTable-component">
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.rolesTableContent}>
        <h1 className={styles.pageTitle}>Object Dictionary Object</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId="keyword-search"
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Object Dictionary Object" className={styles.createRecordBtnStyle} onClick={createNewFunc} data-testid="create-button" />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>

        <div className={[styles.rolesTable, 'glbRolesTable'].join(' ')}>
          <TableRolesTable
            data={data}
            EditRowDataFunc={EditRowDataFunc}
            DeleteRowDataFunc={DeleteRowDataFunc}
            setDeleteModalShow={setDeleteModalShow}
            fields={rolesTableDetails}
            page={page} // Pass the page state from the parent component
            pageSize={pageSize} // Pass the pageSize state from the parent component
            onPageChange={onPageChange}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <CreateEditRolesTableModal
          show={createEditModalShow}
          isNewRecord={isNewRecord}
          formData={formData}
          setFormData={setFormData}
          fetchRolesTableData={fetchRolesTableData}
          setCreateEditModalShow={setCreateEditModalShow}
          onHide={() => setCreateEditModalShow(false)}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={selectedDeleteRowData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Object Dictionary Object'}
          ></DeleteModal>
        )}

        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} dataTestId="success-growl" />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} dataTestId="error-growl" />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

const CreateEditRolesTableModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [objectTypeList, setObjectTypeList] = useState([]);
  let user_info = JSON.parse(window.sessionStorage.getItem('user_information'));

  useEffect(() => {
    getObjectTypeList({ contextId: user_info?.contextId }).then((res) => {
      setObjectTypeList(res?.data);
    });
  }, []);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const objectTypeFilterData = (inputValue) => {
    return objectTypeList.filter((i) => i.objectType?.toLowerCase().includes(inputValue?.toLowerCase()));
  };

  const loadPermissionList = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      // await response.data.
      objectTypeList.map((res) => {
        options.push({ label: res.objectType, value: res.objectType });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      // await getPermissions(inputValue).then(async (response) => {
      let options = await objectTypeFilterData(inputValue).map((res) => {
        return { label: res.objectType, value: res.objectType };
      });

      callback(options);
      // });
    }
  };

  const onChangeObjectType = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      objectType: selectedOptions.value,
    }));
  };

  const onChangeActiveInd = (selectedOptions) => {
    let value = JSON.parse(selectedOptions?.target?.value);

    // if (!selectedOptions) {
    //   selectedOptions = [];
    // }

    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };

  const AddSubmitFunct = async (data) => {
    try {
      if (
        !props.formData.name ||
        props.formData.name === '' ||
        data.name.length > 200 ||
        data.description.length > 500 ||
        data.objectType === '' ||
        data.objectType === undefined ||
        data.displayTemplate.length > 200
      ) {
        setisRequiredError(true);
        return;
      }
      data.name = data?.name?.trim();
      data.description = data?.description?.trim();
      data.displayTemplate = data?.displayTemplate?.trim();

      props.isLoading(true);
      await createObjectTable(data)
        .then((response) => {
          if (response.data) {
            props.fetchRolesTableData();
            props.setCreateEditModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Object Dictionary Object created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const EditSubmitFunct = async (data) => {
    if (
      !props.formData.contextId ||
      props.formData.contextId === '' ||
      !props.formData.objectId ||
      props.formData.objectId === '' ||
      !props.formData.name ||
      props.formData.name === '' ||
      data.name.length > 200 ||
      data.description.length > 500 ||
      data.objectType === '' ||
      data.objectType === undefined ||
      data.displayTemplate.length > 200
    ) {
      setisRequiredError(true);
      return;
    }

    data.name = data.name?.trim();
    data.description = data?.description?.trim();
    data.displayTemplate = data?.displayTemplate?.trim();

    props.isLoading(true);
    await updateObjectTable(data).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Object Dictionary Object updated successfully');
        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);
        props.fetchRolesTableData();
        props.setCreateEditModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');
        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={[styles.rolesTableModal, 'glbRolesTableModalStyle'].join(' ')}
        onHide={props.onHide}
        data-testid="create-edit-modal"
      >
        <div>
          <Modal.Header>
            <div className={styles.modalHeader}>
              <div data-testid="modal-heading">
                <h2 className={styles.modalHeaderTitle}>{props.isNewRecord ? 'Create Object Dictionary Object' : 'Edit Object Dictionary Object'}</h2>
              </div>
              <div
                className={styles.modalHeaderIcon}
                onClick={() => {
                  props.setCreateEditModalShow(false);
                }}
              >
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </Modal.Header>

          <Modal.Body className={styles.rolesTableModalBody}>
            <div className={[styles.modalBodySection, 'row mr-0 ml-0'].join(' ')}>
              {props.isNewRecord ? null : (
                <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={!props.isNewRecord}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                </div>
              )}
              {props.isNewRecord ? null : (
                <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                  <label>Object ID *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={!props.isNewRecord}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.objectId !== '' ? { label: props?.formData?.objectId, value: props?.formData?.objectId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.objectId === null || props.formData.objectId === '') && isRequiredError === true && handleIsRequiredError()}
                </div>
              )}

              <div className={[styles.inputFieldSection, props.isNewRecord ? 'col-12' : 'col-6'].join(' ')}>
                <label>Name *</label>
                <input name="name" value={props.formData.name} type="text" onChange={handleInputChange} data-testid="name-input" />

                {props.formData.name.length > 200 && handleMaxLimitError(200)}
                {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
              {/* <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}> */}
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label>Object Type *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  // isDisabled={props.isNew ? false : true}
                  defaultOptions
                  loadOptions={loadPermissionList}
                  value={props?.formData?.objectType !== '' ? { label: props?.formData?.objectType, value: props?.formData?.objectType } : { label: '---Select---' }}
                  onChange={onChangeObjectType}
                />
                {(props.formData.objectType === null || props.formData.objectType === '' || props.formData.objectType === undefined) && isRequiredError === true && (
                  <div>{handleIsRequiredError()}</div>
                )}
              </div>
              {/* </div> */}
              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label className="mr-2">Active Ind: </label>
                <div data-testid="display-clipboard" className={styles.radioInputOuterDiv}>
                  <input
                    data-testid="display-clipboard-input"
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="activeInd"
                    value={true}
                    checked={props.formData.activeInd === true}
                    onChange={onChangeActiveInd}
                  />
                  <label className="m-2">True</label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="activeInd"
                    value={false}
                    checked={props.formData.activeInd === false}
                    onChange={onChangeActiveInd}
                  />
                  <label className="m-2 ">False</label>
                </div>
              </div>
              {/* </div> */}
              <div className={[styles.inputFieldSection, props.isNewRecord ? 'col-12' : 'col-6'].join(' ')}>
                <label>Display Template</label>
                <input name="displayTemplate" value={props.formData?.displayTemplate} type="text" onChange={handleInputChange} data-testid="displayTemplate-input" />

                {props.formData.displayTemplate.length > 200 && handleMaxLimitError(200)}
              </div>
              <div className={[styles.inputFieldSection, 'col-12'].join(' ')}>
                <label>Description</label>
                <textarea name="description" value={props.formData?.description !== null ? props.formData.description : ''} onChange={handleInputChange} type="text" />
                {props.formData.description.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.rolesTableFooter}>
            <div style={{ display: 'flex', gap: '3rem' }} className={'col-12 justify-content-center'}>
              {props.isNewRecord ? (
                <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => AddSubmitFunct(props.formData)} data-testid="create-submit-button" />
              ) : (
                <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => EditSubmitFunct(props.formData)} />
              )}
              <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => props.setCreateEditModalShow(false)} data-testid="cancel-button" />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
