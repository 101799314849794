import axios from 'axios';
import { serviceUserJwt } from '../../../services/serviceConfig';

// export async function getBuow() {
//   let workQueueFilterResponse = [];
//   try {
//     const url = '/buow_api/userpreference';
//     await axios
//       .get(url, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: serviceUserJwt,
//         },
//       })
//       .then((response) => {
//         workQueueFilterResponse = response;
//       });
//   } catch (ex) {
//     console.log('Error in get messages ', ex.message);
//   }
//   return workQueueFilterResponse;
// }

// export async function createBuow(data) {
//   let createResponse = [];
//   try {
//     const url = '/buow_api/userpreference';
//     await axios
//       .post(url, data, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: serviceUserJwt,
//         },
//       })
//       .then((response) => {
//         createResponse = response;
//       });
//   } catch (ex) {
//     console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
//     createResponse.error = ex.response.data;
//   }
//   // console.log(createResponse);
//   return createResponse;
// }

export async function updateBuow(data) {
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/buow/` + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

// export async function deleteBuow(data) {
//   let deleteResponse = [];
//   try {
//     const url = `/buow_api/userpreference/${data.id}`;
//     await axios
//       .delete(url, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: serviceUserJwt,
//         },
//       })
//       .then((response) => {
//         deleteResponse = response;
//       });
//   } catch (ex) {
//     console.log('Error', ex.message);
//     deleteResponse.error = ex.response.data;
//   }
//   return deleteResponse;
// }

export async function searchBuow(data) {
  let workQueueFilterResponse = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + '/buow_api/buow/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: "Bearer " + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function sortUserPreference(data) {
  let workQueueFilterResponse = [];
  try {
    const url = '/buow_api/systemconfiguration/sort/' + data.column + '/' + data.order;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: serviceUserJwt,
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    workQueueFilterResponse.error = ex.response.data;
  }
  return workQueueFilterResponse;
}

export async function getFolderList(text) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/security_api/permission/search/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
        console.log(response, 'ressss');
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getBuow(value) {
  let contextId = JSON.parse(window.localStorage.getItem('system_config')).contextId;
  let getBuow = [];
  try {
    let url = process.env.REACT_APP_BACKEND_URL + `/buow_api/buow?contextId=${contextId}`;
    url = url + `&keywords=${value}`;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: serviceUserJwt,
        },
      })
      .then((response) => {
        getBuow = response;
      });
  } catch (ex) {
    console.log('Error in get getBuow ', ex.message);
  }
  return getBuow;
}
