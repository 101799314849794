import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './DashboardIndicators.module.scss';
import '../../../GlobalStyle.scss';

import DashboardIndicatorsData from './DashboardIndicatorsData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { createDashboardIndicators, updateOneDashboardIndicators, deleteDashboardIndicators, searchDashboardIndicators, getCardId } from './TableDashboardIndicatorsService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';

const TableDashboardIndicators = lazy(() => import('./TableDashboardIndicators.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Dashboard Indicator', link: '' },
];

const intervalStepData = ['Minute', 'Quarter Hour', 'Half Hour', 'Hour', '4 Hours', '8 Hours', '24 Hours', 'Week', 'Month'];

export default function DashboardIndicators() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [dashboardIndicatorsDetails, setDashboardIndicatorsData] = useState(DashboardIndicatorsData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    includeBenchmarkDisplay: false,
    drillable: false,
    contextId: glbContextId,
    interval: '',
    cardDisplayName: '',
    cardDescription: '',
    displayType: '',
    cardId: '',
  });

  async function fetchDashboardIndicatorsData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchDashboardIndicators(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchDashboardIndicatorsData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
      contextId: glbContextId,
    };
    setSorting({ column: '', order: '' });
    await searchDashboardIndicators(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchDashboardIndicators(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      cardId: '',
      includeBenchmarkDisplay: false,
      drillable: false,
      contextId: glbContextId,
      interval: '',
      displayType: '',
      cardDisplayName: '',
      cardDescription: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      cardId: data.cardId,
      activeInd: data.activeInd,
      includeBenchmarkDisplay: data.includeBenchmarkDisplay,
      drillable: data.drillable,
      contextId: data.contextId,
      interval: data.interval,
      displayType: data.displayType,
      cardDisplayName: data.cardDisplayName,
      cardDescription: data.cardDescription,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewDashboardIndicatorsData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(dashboardIndicatorsDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setDashboardIndicatorsData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewDashboardIndicatorsData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: glbContextId,
    };
    await searchDashboardIndicators(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: glbContextId,
    };
    await searchDashboardIndicators(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: glbContextId,
      cardId: DeleteData.cardId,
      cardDisplayName: DeleteData.cardDisplayName,
      cardDescription: DeleteData.cardDescription,
      displayType: DeleteData.displayType,
      interval: DeleteData.interval,
      id: DeleteData.id,
    };
    deleteDashboardIndicators(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Dashboard Indicator deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="dashboardIndicators-container" className={styles.dashboardIndicatorsPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="dashboardIndicators-page-wrapper" className={styles.dashboardIndicatorsPageContent}>
        <h1 data-testid="dashboardIndicators-heading" className={styles.pageTitle}>
          Dashboard Indicator
        </h1>
        <div>
          <div data-testid="dashboardIndicators-search-container" className={styles.searchSection}>
            <div data-testid="dashboardIndicators-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="dashboardIndicators-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-dashboardIndicators'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="dashboardIndicators-create-btn-container">
                <CustomButton testId={'create-dashboardIndicators-btn'} title="Create Dashboard Indicator" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="dashboardIndicators-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="dashboardIndicators-table-container" className={[styles.dashboardIndicatorsTable, 'glbDashboardIndicatorsTable'].join(' ')}>
          <TableDashboardIndicators
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={dashboardIndicatorsDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <AddEditDashboardIndicatorsModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchDashboardIndicatorsData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          glbContextId={glbContextId}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Dashboard Indicator'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditDashboardIndicatorsModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadIntervalStep = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      intervalStepData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await intervalStepData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const onChangeIntervalStep = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        interval: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      interval: updatedValue,
    }));
  };

  const loadCardIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let payload = { contextId: props.formData.contextId, text: ' ' };
      // await response.data.
      await getCardId(payload).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.cardId, value: res.cardId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      let payload = { contextId: props.formData.contextId, text: inputValue };
      await getCardId(payload).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.cardId, value: res.cardId };
        });

        callback(options);
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const handleSubmit = async (data) => {
    if (!data.cardId || !data.cardDisplayName || !data.displayType || data.cardDisplayName.length > 200 || data.cardDescription.length > 200) {
      setisRequiredError(true);
      return;
    } else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);

      payload.displayType = payload?.displayType?.trim();
      payload.cardDisplayName = payload?.cardDisplayName?.trim();
      payload.cardDescription = payload?.cardDescription?.trim();

      await createDashboardIndicators(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Dashboard Indicator created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchDashboardIndicatorsData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.cardId || !data.cardDisplayName || !data.displayType || data.cardDisplayName.length > 200 || data.cardDescription.length > 200) {
      setisRequiredError(true);
      return;
    } else {
      let payload = _.cloneDeep(data);
      payload.displayType = payload?.displayType?.trim();
      payload.cardDisplayName = payload?.cardDisplayName?.trim();
      payload.cardDescription = payload?.cardDescription?.trim();

      props.isLoading(true);

      await updateOneDashboardIndicators(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Dashboard Indicator updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchDashboardIndicatorsData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const onChangeCardId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        cardId: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? null : label;
    props.setFormData((prevData) => ({
      ...prevData,
      cardId: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  return (
    <Modal
      {...props}
      className={[styles.dashboardIndicatorsModal, 'glbDashboardIndicatorsModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Dashboard Indicator' : 'Edit Dashboard Indicator'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.dashboardIndicatorsModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew === false && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context ID *</label>
                  <input
                    required
                    name="contextId"
                    value={props.formData.contextId}
                    disabled={props.isNew ? false : true}
                    onChange={handleInputChange}
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Card ID *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadCardIdList}
                  value={props?.formData?.cardId !== '' ? { label: props?.formData?.cardId, value: props?.formData?.cardId } : null}
                  onChange={onChangeCardId}
                  isSearchable={true}
                  defaultOptions
                  className={props.isNew ? '' : styles.disabledInput}
                />
                {(props.formData.cardId === null || props.formData.cardId === '' || props.formData.cardId === undefined) && isRequiredError === true && handleIsRequiredError()}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Card Display Name *</label>
                <input required name="cardDisplayName" value={props.formData.cardDisplayName} onChange={handleInputChange} />
                {(props.formData.cardDisplayName === null || props.formData.cardDisplayName === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.cardDisplayName.length > 200 && handleMaxLimitError(200)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Card Description</label>
                <input required name="cardDescription" value={props.formData.cardDescription} onChange={handleInputChange} />
                {props.formData.cardDescription.length > 200 && handleMaxLimitError(200)}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Drillable: <input type="radio" name="drillable" value={true} checked={props.formData.drillable === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="drillable" value={false} checked={props.formData.drillable === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Interval Step</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadIntervalStep}
                  value={props?.formData?.interval !== '' ? { label: props?.formData?.interval, value: props?.formData?.interval } : null}
                  onChange={onChangeIntervalStep}
                  isSearchable={false}
                  defaultOptions
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Display Type *</label>
                <input name="displayType" required value={props.formData.displayType} onChange={handleInputChange} />
                {(props.formData.displayType === null || props.formData.displayType === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldSection].join(' ')}>
                <label>Include Benchmark Display: </label>
                <div data-testid="display-clipboard" className={styles.radioInputOuterDiv}>
                  <input
                    data-testid="display-clipboard-input"
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="includeBenchmarkDisplay"
                    value={true}
                    checked={props.formData.includeBenchmarkDisplay === true}
                    onChange={handleRadioChange}
                  />
                  <label>True</label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="includeBenchmarkDisplay"
                    value={false}
                    checked={props.formData.includeBenchmarkDisplay === false}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2 ">False</label>
                </div>
              </div>

              <div className={[styles.inputFieldSection, 'col-6'].join(' ')}>
                <label className="mr-2">Active Ind: </label>
                <div data-testid="display-clipboard" className={styles.radioInputOuterDiv}>
                  <input
                    data-testid="display-clipboard-input"
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="activeInd"
                    value={true}
                    checked={props.formData.activeInd === true}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2">True</label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="activeInd"
                    value={false}
                    checked={props.formData.activeInd === false}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2 ">False</label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.dashboardIndicatorsFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
