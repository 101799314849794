import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './RedactionReason.module.scss';
import '../../../GlobalStyle.scss';

import RedactionReasonData from './RedactionReasonData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';

import AsyncSelect from 'react-select/async';
import { createRedactionReason, updateOneRedactionReason, deleteRedactionReason, searchRedactionReason } from './RedactionReasonService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';

import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices.js';

const TableRedactionReason = lazy(() => import('./TableRedactionReason.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Redaction Reason', link: '' },
];
export default function RedactionReason() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [redactionReasonDetails, setRedactionReasonData] = useState(RedactionReasonData);
  const [inputDisabled, setInputDisabled] = useState(false);
  const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

  const [formData, setFormData] = useState({
    activeInd: true,
    redactionReason: '',
    contextId: userInformation?.contextId,
    redactionReasonText: '',
    description: '',
    id: '',
  });

  const onRemoveSort = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRedactionReason(payload).then((response) => {
      console.log(response.data);
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchRedactionReason(payload).then((response) => {
      console.log(response.data);
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    setFormData({
      activeInd: true,
      redactionReason: '',
      contextId: userInformation?.contextId,
      redactionReasonText: '',
      description: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd,
      redactionReason: data.redactionReason,
      contextId: data.contextId,
      redactionReasonText: data.redactionReasonText,
      description: data.description,
      id: data.id,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewRedactionReasonData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRedactionReasonData = JSON.parse(JSON.stringify(RedactionReasonData));
    newRedactionReasonData.forEach((itm) => {
      itm.sortType = '';
    });
    setRedactionReasonData(newRedactionReasonData);
    await fetchNewRedactionReasonData();
  };

  // Code needs to change
  const fetchNewRedactionReasonData = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    let payload = {
      contextId: userInformation?.contextId,
      text: '',
      column: '',
      order: '',
    };
    await searchRedactionReason(payload).then((response) => {
      console.log(response.data);
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      contextId: userInformation?.contextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
    };
    await searchRedactionReason(payload).then((response) => {
      console.log(response.data);
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    console.log('DeleteData', DeleteData);
    setLoading(true);
    const sendData = {
      redactionReason: DeleteData.redactionReason,
      contextId: DeleteData.contextId,
    };
    deleteRedactionReason(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Redaction Reason deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="redactionreason-container" className={styles.redactionReasonPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="redactionreason-page-wrapper" className={styles.redactionReasonPageContent}>
        <h1 data-testid="redactionreason-heading" className={styles.pageTitle}>
          Redaction Reason
        </h1>
        <div>
          <div data-testid="redactionreason-search-container" className={styles.searchSection}>
            <div data-testid="redactionreason-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="redactionreason-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-redactionreason'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="redactionreason-create-btn-container">
                <CustomButton testId={'create-redactionreason-btn'} title="Create Redaction Reason" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="redactionreason-help-text" className="searchNoteStyle">
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="redactionreason-table-container" className={[styles.redactionReasonTable, 'glbRedactionReasonTable'].join(' ')}>
          {console.log('TableRedactionReason', data)}
          <TableRedactionReason
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={redactionReasonDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditRedactionReasonModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchRedactionReasonData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Redaction Reason'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditRedactionReasonModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
    }),
    menu: (base) => ({
      ...base,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        callback(options);
      });
    }
  };

  const handleSubmit = async (data) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    console.log('handleSubmit', data);
    if (!data.contextId || !data.redactionReasonText || props.formData.redactionReasonText?.length > 200 || props.formData?.description?.length > 500) {
      setisRequiredError(true);
      return;
    } else {
      setisRequiredError(false);
      props.isLoading(true);
      let payload = _.cloneDeep(data);

      payload.redactionReasonText = payload?.redactionReasonText?.trim();
      payload.description = payload?.description?.trim();
      payload.contextId = userInformation?.contextId;

      await createRedactionReason(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Redaction Reason created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchRedactionReasonData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (
      !data.contextId ||
      !data.redactionReason ||
      !data.redactionReasonText ||
      data.contextId.length > 50 ||
      props.formData.redactionReasonText?.length > 200 ||
      props.formData?.description?.length > 500 ||
      data.redactionReason.length > 35
    )
      setisRequiredError(true);
    else {
      setisRequiredError(false);
      let payload = _.cloneDeep(data);

      payload.redactionReasonText = payload?.redactionReasonText?.trim();
      payload.description = payload?.description?.trim();

      props.isLoading(true);

      await updateOneRedactionReason(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Redaction Reason updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchRedactionReasonData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  return (
    <Modal
      {...props}
      className={[styles.redactionReasonModal, 'glbRedactionReasonModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Redaction Reason' : 'Edit Redaction Reason'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.redactionReasonModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <>
                <div className={styles.inputFieldSection}>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Context Id *</label>
                    <AsyncSelect
                      styles={customStyles}
                      isClearable
                      isDisabled={props.isNew ? false : true}
                      loadOptions={loadContextIdList}
                      value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                      onChange={onChangeContextId}
                    />
                    {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                    {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                  </div>
                  <div className={!props.isNew ? styles.inputFieldInternalDiv : styles.inputFieldInternalDivCreate}>
                    <label>Redaction Reason *</label>
                    <input
                      required
                      type="number"
                      name="redactionReason"
                      value={props.formData.redactionReason}
                      onChange={handleInputChange}
                      disabled={props.isNew ? false : true}
                    />
                    {(props.formData.redactionReason === null || props.formData.redactionReason === '') && isRequiredError === true && handleIsRequiredError()}
                    {props.formData.redactionReason.length > 35 && isRequiredError === true && handleMaxLimitError(35)}
                  </div>
                </div>
              </>
            )}

            <div className={styles.inputFieldSection}>
              {!props.isNew ? (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Redaction Reason Text *</label>
                  <input name="redactionReasonText" required value={props.formData.redactionReasonText} onChange={handleInputChange} />
                  {(props.formData.redactionReasonText === null || props.formData.redactionReasonText === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.redactionReasonText?.length > 200 && handleMaxLimitError(200)}
                </div>
              ) : (
                <>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Redaction Reason Text *</label>
                    <input name="redactionReasonText" required value={props.formData.redactionReasonText} onChange={handleInputChange} />
                    {(props.formData.redactionReasonText === null || props.formData.redactionReasonText === '') && isRequiredError === true && handleIsRequiredError()}
                    {props.formData.redactionReasonText?.length > 200 && handleMaxLimitError(200)}
                  </div>
                </>
              )}
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Description</label>
                <input name="description" value={props.formData.description} onChange={handleInputChange} />
                {props.formData?.description?.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>

            {!props.isNew ? (
              <>
                <div className={styles.inputFieldSection}>
                  <div className={styles.inputFieldInternalDiv}>
                    <div className={styles.radioInputOuterDiv}>
                      <label className="mr-2">Active Ind : </label>
                      <input
                        style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                        type="radio"
                        name="activeInd"
                        value={true}
                        checked={props.formData.activeInd === true}
                        onChange={handleRadioChange}
                      />
                      <label className="m-2">True</label>
                      <input
                        style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                        type="radio"
                        name="activeInd"
                        value={false}
                        checked={props.formData.activeInd === false}
                        onChange={handleRadioChange}
                      />
                      <label className="m-2 ">False</label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.inputFieldSection}>
                  <div className={styles.inputFieldInternalDiv}>
                    <div className={styles.radioInputOuterDiv}>
                      <label className="mr-2">Active Ind : </label>
                      <input
                        style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                        type="radio"
                        name="activeInd"
                        value={true}
                        checked={props.formData.activeInd === true}
                        onChange={handleRadioChange}
                      />
                      <label className="m-2">True</label>
                      <input
                        style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                        type="radio"
                        name="activeInd"
                        value={false}
                        checked={props.formData.activeInd === false}
                        onChange={handleRadioChange}
                      />
                      <label className="m-2 ">False</label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.redactionReasonFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
