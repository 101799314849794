import React, { useEffect, useState } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import DrmsSearch from './components/search/DrmsSearch';
// import ThumbnailsSearch from './components/search/ThumbnailsSearch/ThumbnailsSearch';
import Dashboard from './components/Dashboard/Dashboard';
import FilePlan from './components/FilePlan/NewFilePlan';
import FileCodeSearch from './components/FileCode/FileCodeSearch';
import SuspenseQueue from './components/SuspenseQueue/SuspenseQueue';
import HoldManagement from './components/Holds/HoldManagement';
import TransferManagement from './components/Transfer/TransferManagement';
import SideNavBar from './components/SideNavBar/SideNavBar';
import InBasketPage from './components/InBasketPage/InBasketPage';
// import BUOWPage from './components/BUOWPage/BUOWPage';

// import SignInPage from './components/Login/SignInPage';
import ScanOnDemand from './components/ScanOnDemand/ScanOnDemand';
import RuleTrigger from './pages/AdminPanel/RuleTrigger/RuleTrigger';

import RuleDefinition from './pages/AdminPanel/RuleDefinition/RuleDefinition';
import RoutingSteps from './pages/AdminPanel/RoutingSteps/RoutingSteps';
import Orchestration from './pages/AdminPanel/Orchestration/Orchestration';
import RedactionActor from './pages/AdminPanel/RedactionActor/RedactionActor';
import RedactionReason from './pages/AdminPanel/RedactionReason/RedactionReason';
import SystemConfiguration from './pages/AdminPanel/SystemConfiguration/SystemConfiguration';
import UsersTable from './pages/AdminPanel/UsersTable/UsersTable';
import RolesTable from './pages/AdminPanel/RolesTable/RolesTable';
import PermissionsTable from './pages/AdminPanel/PermissionsTable/PermissionsTable';
import BuowTable from './pages/AdminPanel/BUOW/Buow';
import AdministratorPanel from './pages/AdminPanel/AdminSearchPage/AdminSearchPage';
import UserGuide from './pages/AdminPanel/UserGuide/UserGuide';
import InBasketTable from './pages/AdminPanel/InBasketTable/InBasketTable';
import PackagerConfiguration from './pages/AdminPanel/PackagerConfiguration/PackagerConfiguration';
import DashboardIndicatorsGroup from './pages/AdminPanel/DashboardIndicatorsGroup/DashboardIndicatorsGroup';
import DashboardIndicators from './pages/AdminPanel/DashboardIndicators/DashboardIndicators';
import DashboardIndicatorsDataSetCardXRef from './pages/AdminPanel/DashboardIndicatorsDataSetCardXRef/DashboardIndicatorsDataSetCardXRef';
import DashboardGroupCardXRef from './pages/AdminPanel/DashboardGroupCardXRef/DashboardGroupCardXRef';
import SecRoleDashboardCardXRef from './pages/AdminPanel/SecRoleDashboardCardXRef/SecRoleDashboardCardXRef';
import DashboardCardBenchmarkDisplay from './pages/AdminPanel/DashboardCardBenchmarkDisplay/DashboardCardBenchmarkDisplay';
import IndicatorsDataSet from './pages/AdminPanel/IndicatorsDataSet/IndicatorsDataSet';
import Elements from './pages/AdminPanel/ElementsCRUD/Elements';

import Search from './pages/Search/SearchPage'; //New search page
// import Profile from './pages/Profile/Profile';

import Head from './components/Head/head';
// import Foot from './components/Foot/foot';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import PrivateRoute from './PrivateRoute';
import DashboardSMC from './components/Dashboard/DashboardOld';
import ManageDisposition from './components/ManageDisposition/ManageDisposition';

import Login from './components/Login/Login';
import { Route } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import SearchFolderTable from './pages/AdminPanel/SearchFolderOptions/SearchFolderOptionsTable';
import UserPreference from './pages/AdminPanel/UserPreference/UserPreference';
import AdministratorRoute from './AdministratorRoute';
import NoAccess from './pages/NoAccess/NoAccess';
import UserInfo from './pages/AdminPanel/UserInfo/UserInfo';
import packageJson from '../package.json';
import BUOWDisplayName from './pages/AdminPanel/BUOWDisplayName/BUOWDisplayName';
import WorkGroup from './pages/AdminPanel/WorkGroup/WorkGroup';
import RuleLog from './pages/AdminPanel/RuleLog/RuleLog';
import Messages from './pages/AdminPanel/Messages/Message';
import UnitOfWork from './pages/AdminPanel/UnitOfWork/UnitOfWork';
import WorkGroupUserXWalk from './pages/AdminPanel/WorkGroupUserXWalk/WorkGroupUserXWalk';
import ObjectHierarchyTable from './pages/AdminPanel/ObjectHierarchyTable/ObjectHierarchy';
import RedactionHistoryTable from './pages/AdminPanel/RedactionHistoryTable/RedactionHistory';

import ObjectDefinition from './pages/AdminPanel/ObjectDefinition/ObjectDefinition';

import RedactionDefinition from './pages/AdminPanel/RedactionDefinition/RedactionDefinition';

import ObjectDictionaryObjectTable from './pages/AdminPanel/ObjectDictionaryObject/ObjectDictionaryObject';
import ObjectDictionaryObjectTypeTable from './pages/AdminPanel/ObjectDictionaryObjectType/ObjectDictionaryObjectType';
import BatchDefinitionTable from './pages/AdminPanel/BatchDefinitionTable/BatchDefinition';

import PackagerPreferredOrderTable from './pages/AdminPanel/PackagerPreferredOrderTable/PackagerPreferredOrder';

import BatchXwalk from './pages/AdminPanel/BatchXwalk/BatchXwalk';
import ObjectDefinitionTable from './pages/AdminPanel/ObjectDefinitionCRUD/ObjectDefinitionTable';
import RoutingConditions from './pages/AdminPanel/RoutingConditions/RoutingConditions';
const BUOWPage = React.lazy(() => import('./components/BUOWPage/BUOWPage'));
// import BUOWPage from  "./components/BUOWPage/BUOWPage";

const buildDateGreaterThan = (latestDate, currentDate) => {
  if (latestDate > currentDate) {
    return true;
  } else {
    return false;
  }
};

//import queryString from 'query-string';
const RouterControl = () => {
  let query = useQuery();
  const history = useHistory();
  const onAuthRequired = () => history.push('/');
  const currentPath = window.location.pathname;

  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  useEffect(() => {
    // Your code to handle URL change and trigger a rerender
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDateTime;
        const currentVersionDate = packageJson.buildDateTime;
        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
        console.log(packageJson.version, 'APP_VERSION');
        if (shouldForceRefresh) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      });
  }, [currentPath]); // Only rerender on pathname change

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const oktaAuth = new OktaAuth({
    issuer: `https://${process.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    onAuthRequired: onAuthRequired,
    pkce: true,
    offline_access: true,
    tokenManager: {
      storage: 'sessionStorage',
    },
    postLogoutRedirectUri: `${window.location.origin}/login`,
  });

  /* Start->Commented changes for login page */

  // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   history.replace(toRelativeUrl(originalUri || "/inbasketpage", window.location.origin));
  // };

  /* End->Commented changes for login page */

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/inbasketpage', window.location.origin));
  };
  return (
    <div>
      {/* NOTE:-  Added Security tag for OKTA Integration */}
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {/* Start->Commented changes for login page */}
        {/* {currentPath !== '/' && <Head></Head> }
                  <div style={ currentPath !== '/' ? {display: "flex"} : {} }>
                  {currentPath !== '/' && <SideNavBar></SideNavBar> } */}
        {/* End->Commented changes for login page */}
        {currentPath !== '/login' && currentPath !== '/oktalogin' && currentPath !== '/access-error' && <Head> </Head>}
        <div
          style={
            currentPath !== '/login' && currentPath !== '/oktalogin'
              ? {
                  display: 'flex',
                }
              : {}
          }
        >
          {currentPath !== '/login' && currentPath !== '/oktalogin' && currentPath !== '/access-error' && <SideNavBar> </SideNavBar>}
          <Switch>
            {process.env.REACT_APP_FE_ENVIRONMENT !== undefined ? (
              <PrivateRoute query={query.get('query')} path="/scan" component={ScanOnDemand} />
            ) : (
              <PrivateRoute query={query.get('query')} path="/scan" component={SuspenseQueue} />
            )}
            <PrivateRoute query={query.get('query')} path="/search" component={DrmsSearch} />
            {/* <PrivateRoute query={query.get('query')} path="/search" component={ThumbnailsSearch} /> */}
            {/* <PrivateRoute query={query.get('query')} path="/fileplan" component={FilePlan} />
                            <PrivateRoute query={query.get('query')} path="/filecode" component={FileCodeSearch} />
                            <PrivateRoute query={query.get('query')} path="/suspenseQueue" component={SuspenseQueue} />
                            <PrivateRoute query={query.get('query')} path="/holds" component={HoldManagement} />
                            <PrivateRoute query={query.get('query')} path="/transfer" component={TransferManagement} /> */}
            {/* <PrivateRoute query={query.get('query')} path="/dashboard" component={Dashboard} /> */}
            <PrivateRoute query={query.get('query')} path="/buow/:id" component={BUOWPage} />
            <PrivateRoute query={query.get('query')} path="/transfer" component={TransferManagement} />
            <PrivateRoute query={query.get('query')} path="/dashboard" component={Dashboard} />
            <AdminRoute query={query.get('query')} path="/admin" component={AdministratorPanel} />
            <AdminRoute query={query.get('query')} path="/routingsteps" component={RoutingSteps} />
            <AdminRoute query={query.get('query')} path="/orchestration" component={Orchestration} />
            <AdminRoute query={query.get('query')} path="/redaction-actor" component={RedactionActor} />
            <AdminRoute query={query.get('query')} path="/redaction-reason" component={RedactionReason} />
            <AdminRoute query={query.get('query')} path="/packager-configuration" component={PackagerConfiguration} />
            <AdminRoute query={query.get('query')} path="/batch-xwalk" component={BatchXwalk} />
            <AdminRoute query={query.get('query')} path="/systemconfiguration" component={SystemConfiguration} />
            <AdminRoute query={query.get('query')} path="/buowdisplayname" component={BUOWDisplayName} />
            <AdminRoute query={query.get('query')} path="/ruledefinition" component={RuleDefinition} />
            <AdminRoute query={query.get('query')} path="/userstable" component={UsersTable} />
            <AdminRoute query={query.get('query')} path="/rolestable" component={RolesTable} />
            <AdminRoute query={query.get('query')} path="/permissionstable" component={PermissionsTable} />
            <AdminRoute query={query.get('query')} path="/system-folder" component={SearchFolderTable} />
            <AdminRoute query={query.get('query')} path="/user-preference" component={UserPreference} />
            <AdminRoute query={query.get('query')} path="/buowtable" component={BuowTable} />
            <AdminRoute query={query.get('query')} path="/work-group" component={WorkGroup} />
            <AdminRoute query={query.get('query')} path="/userguide" component={UserGuide} />
            <AdminRoute query={query.get('query')} path="/inbaskettable" component={InBasketTable} />
            <AdminRoute query={query.get('query')} path="/ruletrigger" component={RuleTrigger} />
            <AdminRoute query={query.get('query')} path="/unit-of-work" component={UnitOfWork} /> {/* TODO:- DO not remove this codes */}
            <AdminRoute query={query.get('query')} path="/redactionhistorytable" component={RedactionHistoryTable} />
            <AdminRoute query={query.get('query')} path="/messages" component={Messages} />
            <AdminRoute query={query.get('query')} path="/workgroupuserxwalk" component={WorkGroupUserXWalk} />
            <AdminRoute query={query.get('query')} path="/Object-hierarchy-table" component={ObjectHierarchyTable} />
            <AdminRoute query={query.get('query')} path="/elements" component={Elements} />
            <AdminRoute query={query.get('query')} path="/rule-log" component={RuleLog} />
            <AdminRoute query={query.get('query')} path="/redactiondefinition" component={RedactionDefinition} />
            <AdminRoute query={query.get('query')} path="/dashboard-indicators-group" component={DashboardIndicatorsGroup} />
            <AdminRoute query={query.get('query')} path="/dashboard-indicator" component={DashboardIndicators} />
            <AdminRoute query={query.get('query')} path="/dashboard-indicators-dataSet-card-xref" component={DashboardIndicatorsDataSetCardXRef} />
            <AdminRoute query={query.get('query')} path="/dashboard-group-card-xref" component={DashboardGroupCardXRef} />
            <AdminRoute query={query.get('query')} path="/dashboard-card-benchmark-display" component={DashboardCardBenchmarkDisplay} />
            <AdminRoute query={query.get('query')} path="/sec-role-dashboard-card-xref" component={SecRoleDashboardCardXRef} />
            <AdminRoute query={query.get('query')} path="/indicators-data-set" component={IndicatorsDataSet} />
            <AdminRoute query={query.get('query')} path="/routingconditions" component={RoutingConditions} />
            <AdminRoute query={query.get('query')} path="/objectdefinition" component={ObjectDefinition} />
            <AdminRoute query={query.get('query')} path="/objectdefinitiontable" component={ObjectDefinitionTable} />
            <PrivateRoute query={query.get('query')} path="/packager-preferred-order" component={PackagerPreferredOrderTable} />
            {/* SMC Routes Start */} <Route query={query.get('query')} exact path="/login" component={Login} />
            <PrivateRoute query={query.get('query')} exact path="/" component={InBasketPage} />
            <PrivateRoute query={query.get('query')} exact path="/dashboard" component={DashboardSMC} />
            <PrivateRoute query={query.get('query')} path="/search" component={DrmsSearch} />
            <PrivateRoute query={query.get('query')} path="/holds" component={HoldManagement} />
            <PrivateRoute query={query.get('query')} path="/transfer" component={TransferManagement} />
            <PrivateRoute query={query.get('query')} path="/manage" component={ManageDisposition} />
            <PrivateRoute query={query.get('query')} path="/fileplan" component={FilePlan} />
            <PrivateRoute query={query.get('query')} path="/filecode" component={FileCodeSearch} />
            {/* <PrivateRoute query={query.get('query')} path="/scan" component={SuspenseQueue} /> */}
            <PrivateRoute query={query.get('query')} path="/inbasketpage" component={InBasketPage} />
            <PrivateRoute query={query.get('query')} path="/newsearch" component={Search} />
            <PrivateRoute query={query.get('query')} path="/profile" component={UserInfo} />
            {/* Route for Okta callback */} <Route path="/login/callback" component={LoginCallback} />
            <Route query={query.get('query')} exact path="/access-error" component={NoAccess} />
            <PrivateRoute query={query.get('query')} path="/objectdictionaryobject" component={ObjectDictionaryObjectTable} />
            <PrivateRoute query={query.get('query')} path="/objectdictionaryobjecttype" component={ObjectDictionaryObjectTypeTable} />
            <PrivateRoute query={query.get('query')} path="/batchdefinition" component={BatchDefinitionTable} />
          </Switch>
        </div>
        {/* {currentPath !== '/' && currentPath !== '/login' && currentPath !== '/oktalogin' && <Foot />} */}
      </Security>
    </div>
  );
};
//if url has query string send flag that request was made from url
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default RouterControl;
// function Results() {
//   // The `path` lets us build <Route> paths that are
//   // relative to the parent route, while the `url` lets
//   // us build relative links.
//   let { path, url } = useRouteMatch();
//   let query = useQuery();
//   return (
//     <div>
//       <Switch>
//         <Route path={`${path}/:query`} query={query.get("query")}>
//           <Result />
//         </Route>
//       </Switch>
//     </div>
//   );
// }
// function Result({ query }) {
//   // The <Route> that rendered this component has a
//   // path of `/topics/:topicId`. The `:topicId` portion
//   // of the URL indicates a placeholder that we can
//   // get from `useParams()`.
//  // let { urlQuery } = useParams();
//   return (
//     <div>
//           <LoaderGrid urlQuery={query} />
//     </div>
//   );
// }
