const fixedPermissions = [
  'Update_Transfer',
  'Approve_Management_Disposition',
  'Create_Transfer',
  'Search_Transfer_By_Advance_Search',
  'Search_Transfer_Management_By_Keyword',
  'Remove_Objects_From_Hold',
  'View_Logs_Hold',
  'Update_Hold',
  'Create_Hold',
  'Search_Hold_By_Advance_Search',
  'Search_Hold_By_Keyword',
  'Update_Record_Series',
  'Create_Record_Series',
  'Search_Record_By_Advance_Search_File_Management',
  'Search_Record_Schedule_By_Keyword_File_Management',
  'Create_Retention_Event_Trigger',
  'Create_Retention_Instruction',
  'Add_Document_SOD',
  'Submit_Document_SOD',
  'Remove_Document_SOD',
  'Suspense_Queue_SOD',
  'Successfully_Stored_In_DRMS_SOD',
  'Filter_Search_SOD',
  'Transfer_Management',
  'Disposition_Management',
  'Scan_On_Demand_Page',
  'Record_Schedule',
  'Hold_Management',
  'File_Management',
  'Can_View_Sense',
  'Search_Page',
  'Keyword_Search',
  'Full_Text_Search',
  'Metadata_Search',
  'Folder_Search',
  'Update_File_Code',
  'Delete_Object',
  'View_Object',
  'ObjectMetadata_View_Object',
  'View_Logs',
  'RecordManagement_View_Object',
  'Download_File',
  'Export_Results',
  'Record_Management',
  'View_Dashboard',
  'Administrator_Panel',
  'System_Configuration',
  'Redaction_History',
  'Redaction_Actor',
  'Redaction_Reason',
  'Redaction_Definition',
  'Batch_Xwalk',
  'Packager_Preferred_Order',
  'Packager_Configuration',
  'Batch_Definition',
  'Workgroup',
  'Unit_of_Work',
  'Workgroup_User_Xwalk',
  'BUOW_Display_Name',
  'BUOW',
  'Object_Dictionary_Object_Hierarchy',
  'Elements',
  'Object_Dictionary_Object_Definition',
  'Object_Dictionary_Object_Type',
  'Object_Dictionary_Object',
  'Object_Definition',
  'Routing_Conditions',
  'Rule_Log',
  'Rule_Trigger',
  'Routing_Steps',
  'Routing_Orchestration',
  'Rule_Definition',
  'User_Preference',
  'Users',
  'Roles',
  'Permissions',
  'User_Guide',
  'Messages',
  'In_Basket',
  'System_Folder',
  'Can_View_Xray',
  'Dashboard_Indicators_Group',
  'Dashboard_Group_Card_XRef',
  'Dashboard_Indicators_DataSet_Card_XRef',
  'Dashboard_Indicator',
  'Dashboard_Card_Benchmark_Display',
  'Indicators_Data_Set',
  'Sec_Role_Dashboard_Card_Xref',
  'Delete_Object_Individual',
  'My_In_Basket',
];

export { fixedPermissions };
