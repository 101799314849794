import axios from 'axios';

export async function createRedactionReason(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/redactionreason';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating routing orchestration: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateOneRedactionReason(data) {
  console.log('data ===>>', data);
  let updateResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/buow_api/redactionreason/' + data.redactionReason + `?contextId=${data.contextId}`;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteRedactionReason(params) {
  console.log('delete params', params);
  let deleteResponse = {};
  try {
    const url = `/buow_api/redactionreason/${params.redactionReason}?contextId=${params.contextId}`;
    console.log('deleteOrc: deleteRoutingOrchestration()' + url);
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchRedactionReason(data) {
  console.log('searchRedactionReason', data);
  let workQueueFilterResponse = [];
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/buow_api/redactionreason/search?contextId=${data.contextId}&keywords=${data.text}`;

    if (data.column !== '' && data.order !== '') {
      url += `&sortOn=${data.column}&sortOrder=${data.order}`;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getUserInfoID(text) {
  let workQueueFilterResponse = [];
  try {
    const url = process.env.REACT_APP_BACKEND_URL + '/security_api/user/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
