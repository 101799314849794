import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './DashboardCardBenchmarkDisplay.module.scss';
import '../../../GlobalStyle.scss';

import DashboardCardBenchmarkDisplayData from './DashboardCardBenchmarkDisplayData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import {
  createDashboardCardBenchmarkDisplay,
  updateOneDashboardCardBenchmarkDisplay,
  deleteDashboardCardBenchmarkDisplay,
  searchDashboardCardBenchmarkDisplay,
  getCardId,
} from './TableDashboardCardBenchmarkDisplayService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';

const TableDashboardCardBenchmarkDisplay = lazy(() => import('./TableDashboardCardBenchmarkDisplay.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Dashboard Card Benchmark Display', link: '' },
];

export default function DashboardCardBenchmarkDisplay() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [dashboardIndicatorsDetails, setDashboardCardBenchmarkDisplayData] = useState(DashboardCardBenchmarkDisplayData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    // activeInd: true,
    contextId: glbContextId,
    benchmarkData: '',
    cardId: '',
  });

  async function fetchDashboardCardBenchmarkDisplayData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchDashboardCardBenchmarkDisplay(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchDashboardCardBenchmarkDisplayData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
      contextId: glbContextId,
    };
    setSorting({ column: '', order: '' });
    await searchDashboardCardBenchmarkDisplay(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchDashboardCardBenchmarkDisplay(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      // activeInd: true,
      contextId: glbContextId,
      benchmarkData: '',
      cardId: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      // activeInd: data.activeInd,
      contextId: data.contextId,
      benchmarkData: data.benchmarkData ? JSON.stringify(data.benchmarkData) : data.benchmarkData,
      cardId: data.cardId,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewDashboardCardBenchmarkDisplayData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(dashboardIndicatorsDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setDashboardCardBenchmarkDisplayData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewDashboardCardBenchmarkDisplayData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: glbContextId,
    };
    await searchDashboardCardBenchmarkDisplay(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: glbContextId,
    };
    await searchDashboardCardBenchmarkDisplay(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: glbContextId,
      benchmarkData: DeleteData.benchmarkData,
      id: DeleteData.id,
      cardId: DeleteData.cardId,
    };
    deleteDashboardCardBenchmarkDisplay(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Dashboard Card Benchmark Display deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="dashboardIndicators-container" className={styles.dashboardCardBenchmarkDisplayPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="dashboardIndicators-page-wrapper" className={styles.dashboardCardBenchmarkDisplayPageContent}>
        <h1 data-testid="dashboardIndicators-heading" className={styles.pageTitle}>
          Dashboard Card Benchmark Display
        </h1>
        <div>
          <div data-testid="dashboardIndicators-search-container" className={styles.searchSection}>
            <div data-testid="dashboardIndicators-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="dashboardIndicators-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-dashboardIndicators'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="dashboardIndicators-create-btn-container">
                <CustomButton
                  testId={'create-dashboardIndicators-btn'}
                  title="Create Dashboard Card Benchmark Display"
                  className={styles.createRecordBtnStyle}
                  onClick={createNewFunc}
                />
              </div>
            </div>
            <span data-testid="dashboardIndicators-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="dashboardIndicators-table-container" className={[styles.dashboardCardBenchmarkDisplayTable, 'glbDashboardCardBenchmarkDisplayDataTable'].join(' ')}>
          <TableDashboardCardBenchmarkDisplay
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={dashboardIndicatorsDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <AddEditDashboardCardBenchmarkDisplayModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchDashboardCardBenchmarkDisplayData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          glbContextId={glbContextId}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Dashboard Card Benchmark Display'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditDashboardCardBenchmarkDisplayModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  const [defaultRedactionReasonList, setDefaultRedactionReasonList] = useState([]);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadCardIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let payload = { contextId: props.formData.contextId, text: ' ' };
      // await response.data.
      await getCardId(payload).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.cardId, value: res.cardId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      let payload = { contextId: props.formData.contextId, text: inputValue };
      await getCardId(payload).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.cardId, value: res.cardId };
        });

        callback(options);
      });
    }
  };

  // changed the function because we need a searchable dropdown
  // const loadDefaultRedactionReason = async (inputValue, callback) => {
  //   if (inputValue === '') {
  //     callback([]);
  //     let options = [];
  //     defaultRedactionReasonList.map((res) => {
  //       options.push({ label: res, value: res });
  //       setTimeout(() => {
  //         callback(options);
  //       }, 1000);
  //     });
  //     callback(options);
  //   } else {
  //     let options = await defaultRedactionReasonList.map((res) => {
  //       return { label: res, value: res };
  //     });
  //     callback(options);
  //   }
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      // activeInd: booleanValue,
    }));
  };

  const handleSubmit = async (data) => {
    console.log('handleSubmit', data);
    if (!data.cardId || !data.benchmarkData || data.benchmarkData.length > 500) {
      setisRequiredError(true);
      return;
    } else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);
      payload.benchmarkData = payload?.benchmarkData?.trim();

      // Ensure benchmarkData is an object or array, not a string
      if (typeof payload.benchmarkData === 'string') {
        try {
          console.log('parsed');
          payload.benchmarkData = JSON.parse(payload.benchmarkData);
        } catch (e) {
          console.error('Error parsing benchmarkData:', e);
          props.isLoading(false);
          return;
        }
      }

      await createDashboardCardBenchmarkDisplay(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Dashboard Card Benchmark Display created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchDashboardCardBenchmarkDisplayData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.cardId || !data.benchmarkData || data.benchmarkData.length > 500) {
      setisRequiredError(true);
      return;
    } else {
      let payload = _.cloneDeep(data);
      payload.benchmarkData = payload?.benchmarkData?.trim();
      // Ensure benchmarkData is an object or array, not a string
      if (typeof payload.benchmarkData === 'string') {
        try {
          console.log('parsed');
          payload.benchmarkData = JSON.parse(payload.benchmarkData);
        } catch (e) {
          console.error('Error parsing benchmarkData:', e);
          props.isLoading(false);
          return;
        }
      }

      props.isLoading(true);

      await updateOneDashboardCardBenchmarkDisplay(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Dashboard Card Benchmark Display updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchDashboardCardBenchmarkDisplayData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const onChangeCardId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        cardId: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      cardId: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  return (
    <Modal
      {...props}
      className={[styles.dashboardCardBenchmarkDisplayModal, 'glbDashboardCardBenchmarkDisplayDataModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Dashboard Card Benchmark Display' : 'Edit Dashboard Card Benchmark Display'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.dashboardCardBenchmarkDisplayModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew === false && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context ID *</label>
                  <input
                    required
                    name="contextId"
                    value={props.formData.contextId}
                    disabled={props.isNew ? false : true}
                    onChange={handleInputChange}
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Card ID *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadCardIdList}
                  value={props?.formData?.cardId !== '' ? { label: props?.formData?.cardId, value: props?.formData?.cardId } : null}
                  onChange={onChangeCardId}
                  isSearchable={true}
                  defaultOptions
                  className={props.isNew ? '' : styles.disabledInput}
                />
                {(props.formData.cardId === null || props.formData.cardId === '' || props.formData.cardId === undefined) && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Benchmark Data *</label>
                <textarea
                  required
                  name="benchmarkData"
                  value={props.formData?.benchmarkData !== null ? props.formData.benchmarkData : ''}
                  onChange={handleInputChange}
                  style={{ width: '100%' }}
                  type="text"
                />
                {(props.formData.benchmarkData === null || props.formData.benchmarkData === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.benchmarkData.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>

            {/* <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.dashboardCardBenchmarkDisplayFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
