import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './RuleTrigger.module.scss';
// import styles from './BUOWDisplayName.module.scss';

import '../../../GlobalStyle.scss';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import TableRuletrigger from './TableRuletrigger';
import RuleTriggerdata from './RuleTriggerdata.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import AsyncSelect from 'react-select/async';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import {
  createRuleTrigger,
  updateRuleTrigger,
  deleteRuleTrigger,
  searchRuleTriggerTable,
  getBuowName,
  getDefaultBuowName,
  getDefaultRule,
  getRuleIdBySearch,
  getDefaultElement,
} from './RuleTriggerTableService.js';

const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Rule Trigger', link: '' },
];

const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const TableRuletrigger = lazy(() => import('./TableRuletrigger'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));

export default function RuleTrigger() {
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [formData, setFormData] = useState({
    activeInd: true,
    buowName: '',
    contextId: '',
    id: '',
    objectId: '',
    ruleId: '',
    scope: '',
    mode: '',
    objectElementId: '',
    elementName: '',
    elementId: '',
  });
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [ruleTriggerTableDetails, setRuleTriggerTableData] = useState(RuleTriggerdata);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchRuleTriggerTableData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRuleTriggerTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchRuleTriggerTableData();
  }, []);

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) onSearch(searchText);
  }, [searchText]);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRuleTriggerTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };
  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      buowName: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      id: '',
      objectId: '',
      ruleId: '',
      scope: '',
      mode: '',
      objectElementId: '',
      elementName: '',
      elementId: '',
    });

    setIsNew(true);
    setRecordModalShow(true);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };

    deleteRuleTrigger(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchRuleTriggerTableData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Rule Trigger deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const onSearch = async () => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRuleTriggerTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const fetchNewRuleTriggerTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRuleTriggerTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };
  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRuleTriggerTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };
  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRuleTriggerTableData = JSON.parse(JSON.stringify(ruleTriggerTableDetails));
    newRuleTriggerTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setRuleTriggerTableData(newRuleTriggerTableData);
    await fetchNewRuleTriggerTableData();
  };
  let ruleDisplayName = [];
  getDefaultRule().then((res) => {
    if (res) {
      ruleDisplayName = res.data;
    }
  });

  const editFunc = (data) => {
    let ruleObj = ruleDisplayName.find((res) => {
      return data.ruleId === res.ruleId;
    });
    setFormData({
      contextId: data.contextId,
      buowName: data.buowName,
      objectId: data.objectId,
      activeInd: data.activeInd,
      ruleId: data.ruleId,
      ruleDisplayName: ruleObj?.ruleDisplayName,
      id: data.id,
      scope: data.scope,
      mode: data.mode,
      objectElementId: data.objectElementId,
      elementName: data.elementName,
      elementId: data.elementId,
    });

    setIsNew(false);
    setRecordModalShow(true);
  };

  return (
    <div className={styles.ruleDefinitionPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.ruleDefinitionPageContent}>
        <h1 className={styles.pageTitle}>Rule Trigger</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent searchText={searchText} handleSearch={handleSearch} onSearch={onSearch} clearSearch={clearSearch}></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Rule Trigger" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        {/* table */}
        <div className={[styles.permissionsTableTable, 'glbPermissionsTableTable'].join(' ')}>
          <TableRuletrigger
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={ruleTriggerTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditRuleTriggerTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchRuleTriggerTableData={fetchRuleTriggerTableData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Rule Trigger'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditRuleTriggerTable = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      maxWidth: '70%',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await searchRuleTriggerTable(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };

  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData, props.isNew]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        !props.formData.contextId ||
        props.formData.contextId?.length > 50 ||
        !props.formData.buowName ||
        props.formData.buowName?.length > 35 ||
        !props.formData.objectId ||
        props.formData.objectId?.length > 35 ||
        !props.formData.buowName ||
        !props.formData.objectId ||
        !props.formData.ruleId ||
        !props.formData.scope ||
        !props.formData.scope === ''
      ) {
        setisRequiredError(true);
        return;
      }
      let payload = _.cloneDeep(props.formData);

      payload.objectId = payload?.objectId?.trim();

      delete payload.objectElementId;
      delete payload.elementName;
      props.isLoading(true);
      await createRuleTrigger(payload)
        .then((response) => {
          if (response.data) {
            props.fetchRuleTriggerTableData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Rule Trigger created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };
  const handleEdit = async () => {
    if (
      !props.formData.contextId ||
      props.formData.contextId?.length > 50 ||
      !props.formData.buowName ||
      props.formData.buowName?.length > 35 ||
      !props.formData.objectId ||
      props.formData.objectId?.length > 35 ||
      !props.formData.buowName ||
      !props.formData.objectId ||
      !props.formData.ruleId
    ) {
      setisRequiredError(true);
      return;
    }

    let payload = _.cloneDeep(props.formData);

    payload.objectId = payload?.objectId?.trim();

    props.isLoading(true);

    await updateRuleTrigger(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Rule Trigger updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchRuleTriggerTableData();
        props.setRecordModalShow(false);

        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };
  const loadBuowName = async (inputValue, callback) => {
    // if (props.isNew) {
    if (inputValue === '') {
      // callback([]);
      await getDefaultBuowName(inputValue).then(async (response) => {
        let options = [];
        let specialCharacter = [];
        await response.data.map((res) => {
          if (res.buowName.trim() !== '*') {
            options.push({ label: res.buowName, value: res.buowName });
          } else {
            specialCharacter.push({ label: res.buowName, value: res.buowName });
          }
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        //this logic is to add * option the the botton
        if (specialCharacter.length > 0) {
          specialCharacter.map((character) => {
            options.push(character);
          });
        }
        callback(options);
      });
    } else {
      await getBuowName(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.buowName, value: res.buowName };
        });

        callback(options);
      });
    }
    // }
  };

  const onChangeBuowName = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      buowName: selectedOptions.label,
      // buowName: selectedOptions.map((buowName) => buowName.value),

      // id: selectedOptions.value
    }));
  };

  const loadRuleId = async (inputValue, callback) => {
    // if (props.isNew) {
    if (inputValue === '') {
      // callback([]);
      await getDefaultRule(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.ruleDisplayName, value: res.ruleId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      await getRuleIdBySearch(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.ruleDisplayName, value: res.ruleId };
        });

        callback(options);
      });
    }
    // }
  };

  // function for loading element name and id for element name field dropdown starts here
  const loadElementId = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let payload = { contextId: props.formData.contextId, text: ' ' };
      // await response.data.
      await getDefaultElement(payload).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.elementName, value: res.objectElementId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      let payload = { contextId: props.formData.contextId, text: inputValue };
      await getDefaultElement(payload).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.elementName, value: res.objectElementId };
        });

        callback(options);
      });
    }
  };

  // function for loading element name and id for element name field dropdown ends here

  const onChangeRuleId = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      ruleId: selectedOptions.value,
      ruleDisplayName: selectedOptions.label,
    }));
  };
  // onchange function for setting element name start here
  const onChangeElementName = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      objectElementId: selectedOptions.value,
      elementName: selectedOptions.label,
      elementId: selectedOptions.value,
    }));
  };
  // onchange function for setting element name ends here

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };

  return (
    <Modal
      {...props}
      className={[styles.BUOWDisplayNameModal, 'glbPermissionsTableModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Rule Trigger' : 'Edit Rule Trigger'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.BUOWDisplayNameModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew ? (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Object *</label>
                  {/* <select id="selectFieldBUOWType" name="objectId" value={props.formData.objectId} onChange={handleSelectChange}>
                    <option value={''} disabled>
                      ---Select Type--
                    </option>
                    <option value={'Document'}>Document</option>
                    <option value={'Form'}>Form</option>
                    <option value={'File Review'}>File Review</option>∏<option value={'Envelope Review'}>Envelope Review</option>
                  </select> */}
                  <input type="text" name="objectId" value={props.formData.objectId} onChange={handleSelectChange} />
                  {(props.formData.objectId === null || props.formData.objectId === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                  {props.formData.objectId?.length > 35 && handleMaxLimitError(35)}
                </div>
                <div className={styles.inputFieldInternalDiv}>
                  <label>BUOW Name *</label>
                  <AsyncSelect
                    // isMulti
                    styles={customStyles}
                    isClearable
                    defaultOptions
                    loadOptions={loadBuowName}
                    // value={props?.formData?.buowName?.map((name) => ({
                    //   label: name,
                    //   value: name,
                    // }))}
                    value={props?.formData?.buowName !== '' ? { label: props?.formData?.buowName, value: props?.formData?.id } : { label: '---Select---' }}
                    onChange={onChangeBuowName}
                  />
                  {(isNaN(props.formData.buowName) === undefined || props.formData.buowName === null || props.formData.buowName === '' || !props.formData.buowName) &&
                  isRequiredError === true ? (
                    <div>{handleIsRequiredError()}</div>
                  ) : (
                    (props.formData.systemFolderLevel >= 10 || props.formData.systemFolderLevel <= 1) &&
                    isRequiredError === true && <span className={'requiredErrorMsgStyle'}>Enter values between 2 to 9 only</span>
                  )}
                  {}
                </div>
              </div>
            ) : (
              <div className={styles.inputFieldSection}>
                {/* <div className={styles.inputFieldInternalDiv}></div> */}
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Object *</label>
                  <input type="text" name="objectId" value={props.formData.objectId} onChange={handleSelectChange} />
                  {(props.formData.objectId === null || props.formData.objectId === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                  {props.formData.objectId?.length > 35 && handleMaxLimitError(35)}
                </div>
              </div>
            )}
          </div>

          <div className={styles.inputFieldSection}>
            {props.isNew ? (
              <>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Rule *</label>
                  {/* <input name="ruleId" value={props.formData.ruleId} onChange={handleInputName} /> */}
                  <AsyncSelect
                    styles={customStyles}
                    className={styles.options}
                    isClearable
                    // isDisabled={!props.isNew ? false : true}
                    defaultOptions
                    cacheOptions
                    loadOptions={loadRuleId}
                    value={props?.formData?.ruleId !== '' ? { label: props?.formData?.ruleDisplayName, value: props?.formData?.ruleId } : { label: '---Select---' }}
                    onChange={onChangeRuleId}
                  />
                  {props.formData.ruleId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                  {(props.formData.ruleId === null || props.formData.ruleId === '' || !props.formData.ruleId) && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                </div>

                <div className={styles.inputFieldInternalDiv}>
                  {/* <label>Active Ind</label>
                <input required name="activeInd" value={props.formData.objectId} onChange={handleInputChangeMirroring} disabled />
                {(props.formData.activeInd === null || props.formData.activeInd === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.activeInd?.length > 100 && isRequiredError === true && handleMaxLimitError(100)}
              </div> */}

                  <div className={styles.radioInputOuterDiv}>
                    <label className="mr-2">Active Ind : </label>
                    <input
                      style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                      type="radio"
                      name="activeInd"
                      value={true}
                      checked={props.formData.activeInd === true}
                      onChange={handleRadioChange}
                    />
                    <label className="m-2">True</label>
                    <input
                      style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                      type="radio"
                      name="activeInd"
                      value={false}
                      checked={props.formData.activeInd === false}
                      onChange={handleRadioChange}
                      disabled={true}
                    />
                    <label className="m-2 ">False</label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.inputFieldInternalDiv}>
                  <label>BUOW Name *</label>
                  <AsyncSelect
                    // isMulti
                    styles={customStyles}
                    isClearable
                    defaultOptions
                    loadOptions={loadBuowName}
                    value={props?.formData?.buowName !== '' ? { label: props?.formData?.buowName, value: props?.formData?.id } : { label: '---Select---' }}
                    onChange={onChangeBuowName}
                  />
                  {(isNaN(props.formData.buowName) === undefined || props.formData.buowName === null || props.formData.buowName === '' || !props.formData.buowName) &&
                  isRequiredError === true ? (
                    <div>{handleIsRequiredError()}</div>
                  ) : (
                    (props.formData.systemFolderLevel >= 10 || props.formData.systemFolderLevel <= 1) &&
                    isRequiredError === true && <span className={'requiredErrorMsgStyle'}>Enter values between 2 to 9 only</span>
                  )}
                  {}
                </div>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Rule *</label>
                  <AsyncSelect
                    styles={customStyles}
                    className={styles.options}
                    isClearable
                    defaultOptions
                    cacheOptions
                    loadOptions={loadRuleId}
                    value={props?.formData?.ruleId !== '' ? { label: props?.formData?.ruleDisplayName, value: props?.formData?.ruleId } : { label: '---Select---' }}
                    onChange={onChangeRuleId}
                  />
                  {props.formData.ruleId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                  {(props.formData.ruleId === null || props.formData.ruleId === '' || !props.formData.ruleId) && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                </div>
              </>
            )}
          </div>

          {props.isNew ? (
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Scope *</label>
                <select id="selectFieldMode" name="scope" value={props.formData.scope} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Type--
                  </option>
                  <option value={'NAIX'}>NAIX</option>
                  <option value={'ObjectDriver'}>ObjectDriver</option>
                  <option value={'*'}>*</option>
                </select>
                {(props.formData.scope === null || props.formData.scope === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Mode</label>
                <select id="selectFieldMode" name="mode" value={props.formData.mode} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Type--
                  </option>
                  <option value={'P'}>P</option>
                  <option value={'F'}>F</option>
                  <option value={'I'}>I</option>
                  <option value={'D'}>D</option>
                  <option value={'*'}>*</option>
                </select>
              </div>
            </div>
          ) : (
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  <label className="mr-2">Active Ind : </label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="activeInd"
                    value={true}
                    checked={props.formData.activeInd === true}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2">True</label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="activeInd"
                    value={false}
                    checked={props.formData.activeInd === false}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2 ">False</label>
                </div>
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Scope *</label>
                <select id="selectFieldMode" name="scope" value={props.formData.scope} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Type--
                  </option>
                  <option value={'NAIX'}>NAIX</option>
                  <option value={'ObjectDriver'}>ObjectDriver</option>
                  <option value={'*'}>*</option>
                </select>
                {(props.formData.scope === null || props.formData.scope === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>
          )}

          <div className={styles.inputFieldSection}>
            {props.isNew ? (
              <>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Element Name </label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    // isDisabled={!props.isNew ? false : true}
                    defaultOptions
                    cacheOptions
                    loadOptions={loadElementId}
                    value={props?.formData?.elementName !== '' ? { label: props?.formData?.elementName, value: props?.formData?.objectElementId } : { label: '---Select---' }}
                    onChange={onChangeElementName}
                  />
                  {/* {(props.formData.objectElementId === null || props.formData.objectElementId === '') && isRequiredError === true && handleIsRequiredError()} */}
                </div>
              </>
            ) : (
              <>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Mode</label>
                  <select id="selectFieldMode" name="mode" value={props.formData.mode} onChange={handleSelectChange}>
                    <option value={''} disabled>
                      ---Select Type--
                    </option>
                    <option value={'P'}>P</option>
                    <option value={'F'}>F</option>
                    <option value={'I'}>I</option>
                    <option value={'D'}>D</option>
                    <option value={'*'}>*</option>
                  </select>
                </div>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Element Name </label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    // isDisabled={!props.isNew ? false : true}
                    defaultOptions
                    cacheOptions
                    loadOptions={loadElementId}
                    value={props?.formData?.elementName !== '' ? { label: props?.formData?.elementName, value: props?.formData?.objectElementId } : { label: '---Select---' }}
                    onChange={onChangeElementName}
                  />
                  {/* {(props.formData.objectElementId === null || props.formData.objectElementId === '') && isRequiredError === true && handleIsRequiredError()} */}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" parentWarpper={'d-flex align-items-center'} className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
